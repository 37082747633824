<script>
export default {
  props: {
    imageUrl: {
      required: true,
      type: String,
    },

    isUpdated: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      type: Object,
      default: () => {
        return { width: "", height: "" };
      },
    },
  },
  data() {
    return {
      actionShown: false,
      imageUploaded: false,
      uploadFileList: [],
      newImageUrl: "",
    };
  },
  methods: {
    saveClicked() {
      this.$emit("save-clicked");
    },
    uploadFileChanged(file) {
      // Allow only one file in the file list not multiple
      this.uploadFileList = [file];
      this.$emit("upload-change", [file]);
      this.getImageURlFromFile(file);
      this.imageUploaded = true;
    },
    getImageURlFromFile(fileImage) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.newImageUrl = e.target.result;
      };
      reader.readAsDataURL(fileImage.raw);
    },
    uploadClose() {
      this.imageUploaded = false;
      this.newImageUrl = "";
      this.uploadFileList = [];
      this.$emit("upload-cancelled");
    },
  },
  watch: {
    isUpdated: function (newValue) {
      if (!newValue) {
        this.actionShown = false;
        this.imageUploaded = false;
        this.uploadFileList = [];
        this.newImageUrl = "";
      }
    },
  },
};
</script>
<template>
  <div
    @mouseleave="actionShown = false"
    :style="{ ...size }"
    :class="$style.wrapper"
  >
    <img
      :class="[$style.action_image]"
      v-if="!imageUploaded"
      @mouseover="actionShown = true"
      style="object-fit: scale-down"
      :src="imageUrl"
    />
    <img
      v-else
      :class="[$style.action_image]"
      @mouseover="actionShown = true"
      style="object-fit: scale-down"
      :src="newImageUrl"
    />
    <div
      :class="[
        $style.wrapper_ActionContainer,
        $style[`wrapper_ActionContainer--${actionShown ? 'shown' : 'hidden'}`],
      ]"
    >
      <el-upload
        style="text-align: center"
        :on-change="uploadFileChanged"
        :file-list="uploadFileList"
        action="#"
        :show-file-list="false"
        :multiple="false"
        :auto-upload="false"
      >
        <el-button
          slot="trigger"
          :style="uploadFileList.length > 0 ? 'display:none;' : ''"
          type="primary"
          circle
          icon="el-icon-edit"
          size="mini"
        >
        </el-button>
      </el-upload>

      <el-button
        circle
        v-if="uploadFileList.length > 0"
        type="primary"
        icon="el-icon-check"
        size="mini"
        @click="saveClicked"
      >
      </el-button>
      <el-button
        circle
        v-if="uploadFileList.length > 0"
        type="danger"
        icon="el-icon-close"
        size="mini"
        @click="uploadClose"
      >
      </el-button>
    </div>
  </div>
</template>
<style lang="scss" module>
.wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.action_image {
  display: block;
  // width: 100%;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;

  object-fit: cover;
}
.wrapper_ActionContainer {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.272);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}
.wrapper_ActionContainer--shown {
  bottom: 0;
}
.wrapper_ActionContainer--hidden {
  bottom: -100%;
}
</style>
