<template>
  <div>
    <div class="page-header">
      <div class="page-header__wrapper">
        <div class="page-header__left">
          <h3 class="title">Categories</h3>
        </div>
        <el-button
          type="primary"
          size="default"
          @click="addDialogVisiable = true"
          >Add New Category</el-button
        >
      </div>
    </div>
    <MetricFilterBox
      :defaultFilterData="metricDefaultData"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetircs"
      clearBtnShowen="true"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            @change="filtersChanged(metric.key, $event)"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <div class="body">
      <div class="tableArea">
        <div class="tableArea__wrapper">
          <el-table
            class="customCategoriesTable"
            :row-class-name="tableRowClassName"
            :data="categoriesList"
            border
          >
            <el-table-column prop="image" label="Image" width="200">
              <template slot-scope="scope">
                <div>
                  <div style="text-align: center">
                    <action-image
                      class="customActionItem"
                      :imageUrl="scope.row.image"
                      :isUpdated="scope.row.isUpdated"
                      @save-clicked="saveRow(scope.row, $event)"
                      @upload-change="updateRowImage(scope.row, $event)"
                      @upload-cancelled="onImageUploadCancelled(scope.row)"
                      icon="el-icon-edit"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="id"
              :label="$t('CategoriesPageIDColumnTitle')"
              width="120"
            >
            </el-table-column>
            <el-table-column prop="industry_id" label="Industry" width="180">
              <template slot-scope="scope">
                <div>
                  <el-select
                    v-if="scope.row.isEditable"
                    v-model="scope.row.newIndustryId"
                    placeholder="Select an industry"
                  >
                    <el-option
                      v-for="item in lookups.industries"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <span v-else>
                    {{ getIndustryValue(scope.row.industry_id) }}</span
                  >
                </div>
              </template>
              <!-- {{lookups.industries}} -->
            </el-table-column>

            <el-table-column prop="name" label="Details" width="240">
              <template slot-scope="scope">
                <div>
                  <el-input
                    v-if="scope.row.isEditable"
                    v-model="scope.row.newName"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                  <span v-else> {{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="displayOrder"
              width="200"
              label="Display Order"
            >
              <template slot-scope="scope">
                <div>
                  <el-input
                    v-if="scope.row.isEditable"
                    v-model="scope.row.newDisplayOrder"
                    placeholder=""
                    size="normal"
                    clearable
                  ></el-input>
                  <span v-else> {{ scope.row.displayOrder }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Operations">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="viewCategoryDetails(scope.row.id)"
                  >View Subcategories Details
                </el-button>

                <el-button
                  type="success"
                  size="small"
                  v-if="!scope.row.isEditable"
                  @click="setCategoryEditable(scope.row, true)"
                  >{{ $t("GlobalsEditBtn") }}
                </el-button>
                <template v-else>
                  <el-button
                    type="primary"
                    size="small"
                    @click="updateCategoriesDisplayOrder(scope.row)"
                    >{{ $t("GlobalsSaveBtn") }}
                  </el-button>
                  <el-button
                    type="danger"
                    size="small"
                    @click="setCategoryEditable(scope.row, false)"
                    >{{ $t("GlobalsCancelBtn") }}
                  </el-button>
                </template>
                <el-button
                  type="danger"
                  size="small"
                  @click="deleteCategory(scope.row)"
                  >{{ $t("GLobalsDelete") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div
            class="u-display-flex u-justify-content--center u-top-margin--2x"
          >
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :total="pagination.totalResultsCount"
              :current-page="paginationCurrentPage"
              :page-size="25"
              @current-change="handlePagination"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Add New Category"
      :visible.sync="addDialogVisiable"
      width="60%"
      :before-close="addDialogClosed"
    >
      <el-form
        :model="addForm"
        ref="addForm"
        :rules="newCatRules"
        label-position="top"
        :inline="false"
        size="normal"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item
              :label="$t('CategoriesPageNameDialogLabel')"
              prop="name"
            >
              <el-input v-model="addForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Industry"
              prop="industry_id"
              class="customSelectDropDown"
            >
              <el-select
                v-model="addForm.industry_id"
                placeholder="Select an industry"
              >
                <el-option
                  v-for="item in lookups.industries"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item :label="$t('CategoriesPageDisplayOrderDialogLabel')">
              <el-input v-model="addForm.displayOrder"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('CategoriesPageImageLinkDialogLabel')">
              <el-upload
                action="#"
                class="avatar-uploader"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="onChangeImage"
              >
                <img v-if="addForm.image" :src="addForm.image" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- <el-input v-model="addForm.image"></el-input> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="addDialogClosed">{{
          $t("CategoriesPageCancelDialogLabel")
        }}</el-button>
        <el-button type="primary" @click="validateForm('addForm')"
          >Add</el-button
        >
      </span>
    </el-dialog>
    <absolute-actions-container :visible="listHasUpdatedImages">
      <el-button
        type="primary"
        size="default"
        circle
        @click="bulkEditImages"
        icon="el-icon-check"
      ></el-button>
      <el-button
        type="danger"
        size="default"
        circle
        icon="el-icon-close"
        @click="cancelAllUpdatedImages"
      ></el-button>
    </absolute-actions-container>
  </div>
</template>

<script>
import { categoriesService } from "@/services/categories.service";
import metricbox from "@/components/transaction/metricbox";
import _ from "lodash";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import inputMetric from "../../components/transaction/metricboxComponents/inputMetric.vue";
import AbsoluteActionsContainer from "../../components/AbsoluteActionsContainer.vue";
import ActionImage from "../../components/ActionImage.vue";
import { mapGetters } from "vuex";
import { rules } from "./categories.data";
export default {
  name: "CategoriesPage",
  components: {
    metricbox,
    MetricFilterBox,
    inputMetric,
    AbsoluteActionsContainer,
    ActionImage,
  },
  data() {
    return {
      categoriesList: [],
      metricsList: [],
      metricDefaultData: [
        {
          key: "id",
          label: "Category Id",
          compName: "inputMetric",
        },
        {
          key: "name",
          label: "Category Name",
          compName: "inputMetric",
        },
      ],
      addForm: {
        name: "",
        image: "",
        displayOrder: "",
        industry_id: "",
      },
      filters: {},
      addDialogVisiable: false,
      pagination: {},
      newCatRules: rules,
    };
  },
  created() {
    const metricsSession = JSON.parse(
      localStorage.getItem("categories-metrics")
    );
    if (metricsSession) {
      this.metricsList = metricsSession;
    }
    this.getcategories();
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    currentPage() {
      return this.$route.query.page;
    },
    listHasUpdatedImages() {
      return (
        this.categoriesList.filter((category) => {
          return category.isUpdated;
        }).length > 0
      );
    },
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
  },
  methods: {
    getIndustryValue(x) {
      if (!this.lookups) return;
      let industry = _.find(this.lookups?.industries, { id: x * 1 });
      return industry?.name;
    },
    filtersChanged(key, value) {
      if (!value || value.length == 0) {
        this.filters[key] = undefined;
      }
      this.getcategories(1);
    },
    clearFilters() {
      this.filters = {};
      this.getcategories(1);
    },
    closeMetric(metric) {
      _.remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      //add check if filter exist to avoid unnecessary api calls
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem(
        "categories-metrics",
        JSON.stringify(this.metricsList)
      );
      //fix lodash with reactivity issue
      this.metricsList = [...this.metricsList];
    },
    saveMetircs() {
      localStorage.setItem(
        "categories-metrics",
        JSON.stringify(this.metricsList)
      );
    },
    updateRowImage(row, newImageFile) {
      this.categoriesList = this.categoriesList.map((category) => {
        if (category.id == row.id) {
          category.isUpdated = true;
          category.newImageFile = newImageFile[0].raw;
        }
        return category;
      });
    },
    async bulkEditImages() {
      this.$loading();
      const imagesList = [];
      const categoriesIds = [];
      this.categoriesList.forEach((category) => {
        if (category.isUpdated) {
          imagesList.push(category.newImageFile);
          categoriesIds.push(category.id);
        }
      });
      let imageUploadFD = new FormData();
      imagesList.forEach((image) => {
        imageUploadFD.append("image", image);
      });
      imageUploadFD.append("ids", JSON.stringify(categoriesIds));
      try {
        const response = await categoriesService.bulkEditCategoriesImages(
          imageUploadFD
        );
        await this.getcategories();
        this.$message({ message: response.data, type: "success" });
      } catch (err) {
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    async saveRow(row) {
      this.$loading();
      const image = row.newImageFile;
      const categoriesId = [row.id];
      let imageUploadFD = new FormData();
      imageUploadFD.append("image", image);
      imageUploadFD.append("ids", JSON.stringify(categoriesId));
      try {
        const response = await categoriesService.bulkEditCategoriesImages(
          imageUploadFD
        );

        this.$message({ message: response.data, type: "success" });
        const updatedCategory = this.categoriesList.find((c) => c.id == row.id);
        updatedCategory.isUpdated = false;
        let reader = new FileReader();
        reader.onload = (e) => {
          updatedCategory.image = e.target.result;
          this.categoriesList = [...this.categoriesList];
        };
        reader.readAsDataURL(updatedCategory.newImageFile);
      } catch (err) {
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    cancelAllUpdatedImages() {
      this.categoriesList = this.categoriesList.map((category) => {
        category.isUpdated = false;
        category.newImageFile = null;
        return category;
      });
    },
    onImageUploadCancelled(row) {
      this.categoriesList = this.categoriesList.map((category) => {
        if (category.id == row.id) {
          category.isUpdated = false;
          category.newImageFile = null;
        }
        return category;
      });
    },
    onChangeImage(file) {
      this.addForm.imageFile = file.raw;
      let reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (evt) => {
        this.addForm.image = evt.target.result;
      };
    },
    getcategories(page = this.currentPage) {
      this.$loading();
      return categoriesService
        .getcategories(this.filters, page)
        .then((res) => {
          this.categoriesList = res.categories;
          this.pagination = res.pagination;
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    viewCategoryDetails(id) {
      this.$router.push({
        name: "category",
        params: { id: id },
      });
    },
    addDialogClosed() {
      this.addDialogVisiable = false;
      this.resetForm("addForm");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addForm.displayOrder = undefined;
      this.addForm.image = undefined;
    },
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("this is valid");
          this.createCategory();
        } else {
          console.log("this is not valid");
          return false;
        }
      });
    },
    async createCategory() {
      let categoryObj = {
        displayOrder: this.addForm.displayOrder,
        name: this.addForm.name,
        industry_id: this.addForm.industry_id,
      };
      try {
        let categoryRes = await categoriesService.createCategory(categoryObj);
        const categoryID = categoryRes.data.category.id;
        if (this.addForm.imageFile) {
          let categoryFD = new FormData();
          categoryFD.append("image", this.addForm.imageFile);
          categoryFD.append("ids", JSON.stringify([categoryID]));
          await categoriesService.bulkEditCategoriesImages(categoryFD);
        }
        this.$globalFunctions.popupMessageWrapper(
          `Category Added Successfully`,
          "success",
          4000
        );
        this.getcategories();
        this.resetForm("addForm");
        this.addDialogVisiable = false;
      } catch (err) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(err);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
        // this.$notify({
        //   title: this.$t("GlobalsErrorTitle"),
        //   message: err.response?.data?.error,
        //   type: "error",
        // });
      }

      // });
    },
    async updateCategoriesDisplayOrder(category) {
      try {
        if (category?.newName === ''){
           this.$globalFunctions.popupMessageWrapper(
            `Couldn’t update the category, name field musn’t be empty`,
            'error',
            4000
          );
        }
        else if(!category?.newIndustryId){
          this.$globalFunctions.popupMessageWrapper(
            `Couldn’t update the category, industry field musn’t be empty`,
            'error',
            4000
          );
        }
        else{
           this.$loading();
          await categoriesService.updateCategory(category.id, {
          displayOrder: category.newDisplayOrder,
          name: category.newName,
          industry_id: category.newIndustryId,
        });
        this.$set(category, "isEditable", false);
        this.$set(category, "displayOrder", category.newDisplayOrder);
        this.$set(category, "name", category.newName);
        this.$set(category, "industry_id", category.newIndustryId);
        }
        
    
        
      } catch (error) {
        console.log(error.response.data);
      } finally {
        this.$loading().close();
      }
    },
    setCategoryEditable(category, editable) {
      // adding new properties and use set to fix  the vue reactivity issue
      this.$set(category, "isEditable", editable);
      this.$set(category, "newDisplayOrder", category.displayOrder);
      this.$set(category, "newName", category.name);
      this.$set(category, "newIndustryId", category.industry_id);
    },
    async deleteCategory(category) {
      try {
        this.$loading();
        let CategoryRes = await categoriesService.getCategory(category.id);
        const subCategories = CategoryRes.data.category.subcategories;
        this.$confirm(
          this.$t("CategoriesPageDeleteDialogMessage", [
            subCategories.map((s) => s.name).join(","),
          ]),
          this.$t("CategoriesPageDeleteDialogTItle"),
          {
            confirmButtonText: this.$t("GlobalOkBtn"),
            cancelButtonText: this.$t("GlobalCancelBtn"),
            type: "warning",
          }
        ).then(async () => {
          this.$loading();
          await categoriesService.deleteCategory(category.id);
          this.getcategories();
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$loading().close();
      }
    },

    handlePagination(val) {
      this.getcategories(val);
      this.$router.push({
        query: {
          page: val,
        },
      });
    },
    tableRowClassName({ row }) {
      if (row.isUpdated) {
        return "u-bg--green-lighter no-hover";
      }
      return "no-hover";
    },
  },
};
</script>

<style lang="scss" scoped module>
.header {
  display: flex;
  justify-content: space-between;
}
img {
  height: 100%;
  width: 100%;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px !important;
  text-align: center;
}
</style>
<style lang="scss">
.customCategoriesTable tr th {
  background: #fccd13;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  /* border: unset; */
}
/* .customCategoriesTable{
  tbody{
    tr{
      td{
        border-right: unset;
      }
    }
  }
} */
.customCategoriesTable {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.customActionItem {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 151px;
}
.customSelectDropDown {
  .el-select {
    width: 100%;
  }
}
</style>