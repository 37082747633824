

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="absolute_Container">
    <transition name="fade">
      <div v-if="visible">
        <slot />
      </div>
    </transition>
  </div>
</template>
<style scoped >
.absolute_Container {
  position: absolute;
  bottom: 7%;
  right: 7%;
  transition: 0.3s;
}

[dir="rtl"] .absolute_Container {
  left: 5%;
  right: auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>